namespace Account.Login
{
    export const documentReady = () =>
    {
        const $login = getJQuery({ containerFor: "login" });
        const $unconfirmedEmail = getJQuery({ containerFor: "unconfirmedEmail" });

        $login.setVisibility(true);
        $unconfirmedEmail.setVisibility(false);

        getJQuery({ controlFor: "login" }).addClickEvent(login);

        function login()
        {
            Site.post({ url: window.location.href, form: Page.getForm({ containerFor: "loginForm" }), onSuccess });

            function onSuccess(result: { email: string, loggedIn: boolean, returnURL: string, unconfirmedEmail: boolean, validationErrors: Validation.Error[] })
            {
                Validation.clearAlerts();
                $login.setVisibility(false);
                $unconfirmedEmail.setVisibility(false);

                if (result.loggedIn)
                {
                    window.location.href = result.returnURL;
                }
                else if (result.unconfirmedEmail)
                {
                    $unconfirmedEmail.setVisibility(true);
                    $unconfirmedEmail.getJQuery({ containerFor: "email" }).html(result.email);
                }
                else
                {
                    $login.setVisibility(true);
                    Validation.showAlerts(result.validationErrors);
                }
            }
        }
    }
}

$(document).ready(Account.Login.documentReady);